import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import { useCookies } from 'react-cookie';
import './style.scss'
import { navigate } from 'gatsby-link';
import { Spinner } from 'react-bootstrap';
import CustomerContext from '../../context/customerContext';


const Login = ({ backLink, declinedPaymentCustomer }) => {
  const [email, setEmail] = useState("")
  const [recoveryEmail, setRecoveryEmail] = useState("")
  const [displayForgotPassword, setDisplayForgotPassword] = useState(false)
  const [forgotPasswordSubmitted, setForgotPasswordSubmitted] = useState(false)
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
  const [error, setError] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['user-jwt']);
  const user_token = cookies['user-jwt']
  const { stripeCustomer, setStripeCustomer} = useContext(CustomerContext)

  // new login logic

  const logOut = () => {
    removeCookie('user-jwt', {path: '/'})
    removeCookie('hubbleContactsSessionId')
    setStripeCustomer({})
    localStorage.removeItem('customer_data')
    localStorage.removeItem('upcoming_order')
    localStorage.removeItem('customer_rx_sunglasses_data')
    localStorage.removeItem('customer_rx_glassess_data')
    localStorage.removeItem('discount_code')
    // window.dataLayer = window.dataLayer.filter(x=>! 'user_id' in x);
    // window.location.href = '/'
  }

  const login = async (e) => {
    const headers = {
      headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
    }
    e.preventDefault();
    setLoading(true)
    setError(false)

    const stripeReq = await axios.post(`${process.env.GATSBY_API_URL_STRIPE}/api/v1/stripe/sign_in`, {email: email, password: password}, headers).then((res) => res).catch((err) => err)
    const rechargeReq = await axios.post(`${process.env.GATSBY_API_URL}/api/login`, {email: email, password: password}, headers).then((res) => res).catch((err) => err)

    if (stripeReq.status == "200") {
      setCookie('user-jwt', stripeReq.data.token, {maxAge: 1209600, path: "/"} )

      const headers = {
        headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          Authorization: `Bearer ${stripeReq.data.token}`
        }
      }

      axios.get(`${process.env.GATSBY_API_URL_STRIPE}/api/v1/stripe/customers`, headers)
      .then((customerResponse) => {
        setStripeCustomer({...customerResponse.data, type: "stripe"})
        localStorage.setItem('customer_data', JSON.stringify({...customerResponse.data, type: "stripe"}))
        localStorage.setItem('declinedPaymentCustomer', JSON.stringify(declinedPaymentCustomer))

        if (typeof window !== "undefined") {
          if (window.gtag !== null) {
            window.dataLayer.push({'user_id': stripeReq.data.id})
            window.dataLayer.push({event: "login", method: "email"})
          }
        }

        axios.get(`${process.env.GATSBY_API_URL_STRIPE}/api/v1/stripe/customers/orders`, headers)
        .then((response) => {
          const orders = response.data.orders
          const customerWithOrders = { ...customerResponse.data, type: "stripe", orders: [...orders] }
          setStripeCustomer(customerWithOrders)
          localStorage.setItem('customer_data', JSON.stringify(customerWithOrders))
        })
        .catch((error) => {
          console.log(error)
        })
      }).then(() => {
        setLoading(false)
        navigate(backLink || "/customer-portal/")
      })
      return
    }

    if (rechargeReq.status == "200") {
      setCookie('user-jwt', rechargeReq.data.customer, {maxAge: 1209600, path: "/"} )

      axios.get(`${process.env.GATSBY_API_URL}/api/customer_data`, {
        params: {
          user_token: rechargeReq.data.customer,
        },
      }).then((response) => {
        if (typeof window !== "undefined") {
          if (window.gtag !== null) {
            window.dataLayer.push({'user_id': rechargeReq.data.customer})
            window.dataLayer.push({event: "login", method: "email"})
          }
        }
        setStripeCustomer({...response.data.data, type: "recharge"})
        localStorage.setItem('customer_data', JSON.stringify({...response.data.data, type: "recharge"}))
        axios.get(`${process.env.GATSBY_API_URL}/api/customer_subscription`, {
          params: {
            user_token: rechargeReq.data.customer,
          },
        }).then((response) => {
          localStorage.setItem('upcoming_order', JSON.stringify(response.data.data))
        }).catch((error) => {})
      }).then(() => {
        setLoading(false)
        navigate(backLink || "/customer-portal/")
      }).catch((error) => {})


      return
    }

    setLoading(false)
    setError(true)
  }

  const resetPassword = (e) => {
    e.preventDefault();
    setResetPasswordLoading(true)
    setError(false)
    axios.post(`${process.env.GATSBY_API_URL}/api/reset-password`,{email: recoveryEmail}, { headers: {
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Credentials':true,
      'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }}).then((response) => {
      setResetPasswordLoading(false)
      setDisplayForgotPassword(false)
      setForgotPasswordSubmitted(true)
    }).catch((error) => {
      setResetPasswordLoading(false)
      setError(true)
    })
  }

  useEffect(()=> {
    (user_token || stripeCustomer.id) && navigate(backLink ? backLink : "/customer-portal/")
    if (declinedPaymentCustomer) localStorage.setItem('declinedPaymentCustomer', JSON.stringify(declinedPaymentCustomer))
  }, [user_token, stripeCustomer])

  return (
    <main className="login-page main-content grid-section" role="main">
      <div className="login-wrapper">
        <form method="post">
          <div className="container">
            <div className="grid__item title one-whole text-center">
              <h1 className="heading">Sign in</h1>
            </div>
            <div className="input-container">
              <label htmlFor="CustomerEmail" className="label">
                Email Address
              </label>
              <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} id="CustomerEmail" className="input-full" placeholder="Enter your email address" autoCorrect="off" autoCapitalize="off" autoFocus="" />
              <label htmlFor="CustomerPassword" className="label">
                Password
              </label>
              <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} id="CustomerPassword" className="input-full" placeholder="Enter your Password" />

              { error &&
                <p className="login-error">There was a problem with your login, please check your email and password</p>
              }

              <a className="forgot-password" href="/forgot-password" id="RecoverPassword">
                Forgot Your Password?
              </a>

              <div className='login-button' onClick={(e) => login(e)}>
                <>{!loading ? "Sign In" : <Spinner />}</>
              </div>
            </div>
            <div className="auth-links-wrapper">
              <p className="auth-links-activate">


                {/* {displayForgotPassword &&
                  <div className="input-container">
                    <input type="email" name="email" value={recoveryEmail} onChange={(e) => setRecoveryEmail(e.target.value)} id="CustomerEmail" className="input-full" placeholder="Email" autoCorrect="off" autoCapitalize="off" autoFocus="" />
                    <div className="button-wrapper">
                      <DynamicButton
                      bgColor="white"
                      borderColor='veryDarkGrayishYellow'
                      copy={`${resetPasswordLoading ? 'Submitting...' : 'Submit'}`}
                      disabled={resetPasswordLoading}
                      handleClick={(e) => resetPassword(e)}
                      size="s"
                      textColor='veryDarkGrayishYellow' />
                    </div>
                  </div>
                } */}

                {forgotPasswordSubmitted &&
                  <h3 style={{'font-family': 'NeueHaasUnicaRegular', 'font-size': '18px', 'color':'#000', }}className='text-center mt-5'>Forgot Password Email Sent </h3>
                }
              </p>
              <br />
              <br />
            </div>
          </div>
        </form>
      </div>
    </main >
  )
}

export default Login
